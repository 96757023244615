<template>
  <div class="content">
    <PageHeader title="Enviar mensagem" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <router-link to="/sms/send" class="btn btn-success">
            <i class="icon dripicons-plus color-white"></i>Novo Texto
          </router-link>
        </div>
        <div class="card-body">
          <div class="alert alert-success alert-dismissible fade show" role="alert">
            Sua mensagem foi registrada com sucesso!
          </div>
          <div class="table-responsive">
            <table class="table table-bordered" v-if="sms.length > 0">
              <tbody>
                <tr>
                  <td align="right" width="50%">{{$t('generic-str.send-2')}}</td>
                  <td  width="50%"><span class="badge badge-pill badge-info">{{sms[0].created_at | formatDate}}</span></td>
                </tr>
                <tr>
                  <td align="right" width="50%">De</td>
                  <td width="50%"><span class="badge badge-pill badge-info">{{sms[0].from}}</span></td>
                </tr>
                <tr>
                  <td align="right" width="50%">Para</td>
                  <td width="50%"><span class="badge badge-pill badge-info">{{sms.length}} destinatários</span></td>
                </tr>
                <tr>
                  <td align="right" width="50%">{{$t('generic-str.content')}}</td>
                  <td width="50%">{{sms[0].body}}</td>
                </tr>
                <tr>
                  <td align="right" width="50%">Total de Caractéres</td>
                  <td width="50%">{{sms[0].body.length}}</td>
                </tr>
                <tr>
                  <td align="right" width="50%">{{$tc('generic-str.part', 2)}}</td>
                  <td width="50%">{{sms[0].segments}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      sms: [],
    };
  },
  mounted() {
    this.sms = this.$route.params.sms;
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}
</style>
